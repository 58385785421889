import type { ContextType } from "react";

import { t } from "@lingui/macro";
import type { LocalesContext } from "@mobsuccess-devops/react-ui/Locales";

export function useReactUILocales(): ContextType<typeof LocalesContext> {
  return {
    general: {
      save: t`#src.public.react-ui.general.save`,
      reset: t`#src.public.react-ui.general.reset`,
    },
    select: {
      selectAll: t`#src.public.react-ui.select.select-all`,
      removeAll: t`#src.public.react-ui.select.remove-all`,
    },
    accountDropdown: {
      searchPlaceholder: t`#src.public.react-ui.account-dropdown.search-placeholder`,
    },
    pagination: {
      pageSizeLabel: t`#src.public.react-ui.pagination.page-size-label`,
      pageSizeOptions: (size: number) =>
        t`#src.public.react-ui.pagination.page-size-options(${size})`,
    },
    colorPicker: {
      savedSectionLabel: t`#src.public.react-ui.color-picker.saved-section-label`,
    },
    filters: {
      searchPlaceholder: t`#src.public.react-ui.filters.search-placeholder`,
    },
    table: {
      arrangeColumns: t`#src.public.react-ui.table.arrange-columns`,
    },
    dateRangePicker: {
      reset: "Réinitialiser",
      from: "Du",
      to: "au",
      rangePlaceholder: "Filtrer par date",
      thisWeek: "Cette semaine",
      lastWeek: "La semaine dernière",
      thisMonth: "Ce mois-ci",
      lastMonth: "Le mois dernier",
      thisQuarter: "Ce trimestre",
      lastQuarter: "Le trimestre dernier",
      thisYear: "Cette année",
      lastYear: "L'année dernière",
      last3Days: "Les 3 derniers jours",
      last7Days: "Les 7 derniers jours",
      last14Days: "Les 14 derniers jours",
      last30Days: "Les 30 derniers jours",
      last90Days: "Les 90 derniers jours",
      today: "Aujourd'hui",
      yesterday: "Hier",
    },
    sidebar: {
      termsAndConditions: t`#src.public.react-ui.sidebar.terms-and-conditions`,
    },
  };
}
