import { createContext, useCallback, useContext } from "react";

import { RouterState } from "@remix-run/router";

import { UNSAFE_DataRouterStateContext } from "react-router-dom";

import { KeepParam } from "../../types/KeepParam";

type RouterContext = {
  keep: Array<KeepParam>;
  navigationDelay: number;
  listener: EventTarget;
  dispatchNavigationEvent: () => void;
};

export const RouterContext = createContext<RouterContext | null>(null);

export function useWithinRouterContext(): boolean {
  return useContext(RouterContext) !== null;
}

export function useRouterContext(): RouterContext {
  const context = useContext(RouterContext);
  if (!context) {
    throw new Error("useRouterContext must be used within a RouterProvider");
  }
  return context;
}

export function useRouterStateContext(): RouterState {
  const stateContext = useContext(UNSAFE_DataRouterStateContext);
  if (!stateContext) {
    throw new Error(
      "useRouterStateContext must be used within a RouterProvider",
    );
  }
  return stateContext;
}

export function useKeepParams(): Array<KeepParam> {
  const { keep } = useRouterContext();
  return keep;
}

export function useListenRouteChange(): (
  callback: (event: Event) => void,
) => () => void {
  const { listener } = useRouterContext();

  const listen = useCallback(
    (callback: (event: Event) => void) => {
      listener.addEventListener("navigate", callback);
      return () => listener.removeEventListener("navigate", callback);
    },
    [listener],
  );

  return listen;
}
