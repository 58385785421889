import { createContext, useContext } from "react";

import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { captureException } from "@sentry/react";

import { basename } from "path";

export type LocaleModule = {
  messages: Record<string, string>;
};

export type LanguageContextType = {
  language: string;
  onChangeLanguage: (language: string) => void;
};

export const LanguageContext = createContext<LanguageContextType | null>(null);

export function useLanguageContext(): LanguageContextType {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("LanguageContext is not defined");
  }
  return context;
}

export function useI18n(): I18n {
  const { i18n } = useLingui();
  return i18n;
}

const sharedLocales = import.meta.glob<LocaleModule>(
  "../../locales/!(*.d).ts",
  {
    eager: true,
  },
);

export function buildLocales(
  webappsLocales: Record<string, LocaleModule>,
): Map<string, LocaleModule["messages"]> {
  const allLocales = [
    ...Object.entries(sharedLocales),
    ...Object.entries(webappsLocales),
  ];
  return allLocales.reduce((locales, [key, module]) => {
    const locale = basename(key, ".ts");
    if (!locales.has(locale)) {
      locales.set(locale, {});
    }
    const loaded = locales.get(locale);
    locales.set(locale, {
      ...loaded,
      ...module.messages,
    });
    return locales;
  }, new Map<string, LocaleModule["messages"]>());
}

const crowdinDistributionHash = "9e231ff130316b88a973b29t6jz";
const failedDistantLocales: Set<string> = new Set();
const distantLocales: Map<string, LocaleModule["messages"]> = new Map();
let crowdinManifestTimestamp: number | undefined = undefined;
const crowdinLanguageMapping: Record<string, string> = {
  en: "en_US",
  fr: "fr_FRO",
};

async function fetchCrowdinDistributionManifest() {
  if (typeof crowdinManifestTimestamp === "number") {
    return crowdinManifestTimestamp;
  }
  try {
    const res = await fetch(
      `https://distributions.crowdin.net/${crowdinDistributionHash}/manifest.json`,
    );
    const manifest = await res.json();
    if (typeof manifest.timestamp === "number") {
      crowdinManifestTimestamp = manifest.timestamp;
      return manifest.timestamp;
    }
  } catch (e) {
    captureException(e);
    return undefined;
  }
}

async function fetchDistantLocale(projectId: string, locale: string) {
  const cacheKey = `${projectId}_${locale}`;
  if (distantLocales.has(cacheKey)) {
    return distantLocales.get(cacheKey);
  }
  if (failedDistantLocales.has(cacheKey)) {
    return undefined;
  }
  const crowdinLocale = crowdinLanguageMapping[locale] ?? locale;
  try {
    const timestamp = await fetchCrowdinDistributionManifest();
    const res = await fetch(
      `https://distributions.crowdin.net/${crowdinDistributionHash}/content/${projectId}_${crowdinLocale}.json?timestamp=${timestamp}`,
    );
    const messages = await res.json();
    distantLocales.set(cacheKey, messages);
    return messages;
  } catch (e) {
    // we don't want to block the app if the distant locale is not available, but we want to know about it
    captureException(e);
    failedDistantLocales.add(cacheKey);
    return undefined;
  }
}

export async function activate(
  i18n: I18n,
  locales: Map<string, LocaleModule["messages"]>,
  language: string,
): Promise<void> {
  const distantReactSharedLocale = await fetchDistantLocale(
    "react-shared",
    language,
  );
  let distantLocale = undefined;
  const projectId = import.meta.env.VITE_MOON_PROJECT_ID;
  if (projectId) {
    distantLocale = await fetchDistantLocale(projectId, language);
  }
  const locale = locales.get(language);
  if (!distantLocale && !locale) {
    throw new Error(`Language ${language} is not supported`);
  }
  const catalog = { ...locale, ...distantReactSharedLocale, ...distantLocale };

  i18n.load(language, catalog);
  i18n.activate(language);
}
