import { JSX, ReactNode, useMemo } from "react";

import { AtomStoreProvider } from "@mobsuccess-devops/react-atom";

import { Context, useContextValue } from "../../features/context/hooks";
import { configureInterceptors } from "../../features/interceptors";
import type { Config, ContextData } from "../../types/context";

type DataContextBaseProps = {
  config?: Config;
  data?: ContextData;
  children: ReactNode;
  initialStore?: Record<string, unknown>;
  servicesOverrides?: Record<string, string>;
  fallback?: JSX.Element | null;
};

const defaultObject = {};

function DataContextBase({
  config = defaultObject,
  data = defaultObject,
  children,
  initialStore = defaultObject,
  servicesOverrides = defaultObject,
  fallback = null,
}: DataContextBaseProps): JSX.Element {
  useMemo(() => {
    configureInterceptors();
  }, []);

  const contextValue = useContextValue({ config, data, servicesOverrides });
  const atomStore = useMemo(() => {
    // prefix all static data with "static:"
    return Object.entries(data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [`static:${key}`]: value,
      }),
      initialStore,
    );
  }, [data, initialStore]);

  if (!contextValue) {
    // @ts-expect-error TODO fix this error
    return fallback ?? null;
  }

  return (
    <AtomStoreProvider initialStore={atomStore}>
      {/* @ts-expect-error TODO fix this error */}
      <Context.Provider value={contextValue}>{children}</Context.Provider>
    </AtomStoreProvider>
  );
}

export default DataContextBase;
