import {
  type ReactNode,
  type JSX,
  useMemo,
  createContext,
  useContext,
} from "react";
import { cloneElement } from "react";

import {
  cx,
  sva,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { vstack } from "@mobsuccess-devops/styled-system/patterns";

import { createPortal } from "react-dom";

import { composition } from "../../../features/utils/composition";
import { warnOnce } from "../../../features/utils/warn-once";
import { Typo } from "../../Typo";

import { Dot } from "./Dot";
import { Ellipsis } from "./Ellipsis";

const LoaderContext =
  createContext<RecipeVariantProps<typeof styles>>(undefined);

type DescriptionProps = {
  children: ReactNode;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function Description({ children }: DescriptionProps): JSX.Element {
  const config = useContext(LoaderContext);
  if (!config) {
    throw new Error("Description must be used inside a Loader component");
  }
  const classes = styles(config);

  return <Typo.Body className={classes.description}>{children}</Typo.Body>;
}

export type BlockProps = RecipeVariantProps<typeof styles> & {
  className?: string;
  children: ReactNode;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function Block({
  size,
  className,
  variant = "light",
  palette = "interface",
  children,
  shown,
}: BlockProps): JSX.Element | null {
  const classes = styles({ size, variant, shown, palette });

  const { spinner, description } = composition.split(children, {
    spinner: composition.single([Dot, Ellipsis]),
    description: composition.single(Description),
  });

  const config = {
    size,
    variant,
  };

  if (spinner.props.size || spinner.props.color || spinner.props.shown) {
    warnOnce(
      "Loader spinner is within a block, the size, color and shown props on the spinner will be ignored",
    );
  }

  return (
    <LoaderContext.Provider value={config}>
      <div className={cx("block-loader", classes.block, className)}>
        {cloneElement(spinner, {
          size,
          color:
            variant.startsWith("light") || variant === "transparent"
              ? "dark"
              : "light",
          shown,
        })}
        {description}
      </div>
    </LoaderContext.Provider>
  );
}

type PortalBlockProps = BlockProps & {
  to: string | HTMLElement;
};

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
export function PortalBlock({ to, children }: PortalBlockProps): JSX.Element {
  const element = useMemo(() => {
    if (typeof to === "string") {
      return document.getElementById(to);
    }
    return to;
  }, [to]);

  if (!element) {
    return <Block>{children}</Block>;
  }

  return createPortal(<Block>{children}</Block>, element);
}

export const Loader = {
  Block,
  PortalBlock,
  Description,
  Dot,
  Ellipsis,
};

const styles = sva({
  slots: ["block", "description"],
  base: {
    block: {
      zIndex: 1000,
      top: 0,
      left: 0,
      size: "full",
      center: true,
      ...vstack.raw(),
      position: "absolute",
      background:
        "color-mix(in srgb, var(--background-color, white) 95%, transparent)",
    },
  },
  variants: {
    size: {
      sm: {
        block: {
          gap: "sm",
        },
        description: {
          textStyle: "muted",
          textAlign: "center",
        },
      },
      md: {
        block: {
          gap: "md",
        },
        description: {
          textStyle: "body",
          textAlign: "center",
        },
      },
      lg: {
        block: {
          gap: "sm",
        },
        description: {
          textStyle: "lg",
          textAlign: "center",
        },
      },
    },
    variant: {
      transparent: {
        block: {
          "--background-color": "transparent",
        },
        description: {
          color: "interface",
        },
      },
      lighter: {
        block: {
          "--background-color": "white",
        },
        description: {
          color: "interface",
        },
      },
      light: {
        block: {
          "--background-color": "token(colors.interface.100)",
        },
        description: {
          color: "interface",
        },
      },
      dark: {
        block: {
          "--background-color": "token(colors.interface)",
        },
        description: {
          color: "white",
        },
      },
      darker: {
        block: {
          "--background-color": "token(colors.interface.600)",
        },
        description: {
          color: "white",
        },
      },
    },
    palette: {
      primary: {},
      interface: {},
    },
    shown: {
      false: {
        block: {
          display: "none",
        },
      },
    },
  },
  compoundVariants: [
    {
      variant: "dark",
      palette: "primary",
      css: {
        block: {
          "--background-color": "token(colors.primary)",
        },
      },
    },
    {
      variant: "dark",
      palette: "interface",
      css: {
        block: {
          "--background-color": "token(colors.interface)",
        },
      },
    },
    {
      variant: "darker",
      palette: "primary",
      css: {
        block: {
          "--background-color": "token(colors.primary.600)",
        },
      },
    },
    {
      variant: "darker",
      palette: "interface",
      css: {
        block: {
          "--background-color": "token(colors.interface.600)",
        },
      },
    },
  ],
  defaultVariants: {
    size: "md",
    variant: "light",
    shown: true,
  },
});
