import amplifyOutputs from "@/backend-data-amplify-config.json";
import { Schema } from "@/backend-data-schema";

import { generateClient } from "aws-amplify/data";

/**
 * @returns The Amplify outputs from the backend.
 */
export async function getAmplifyOutputs(): Promise<object | undefined> {
  if (Object.keys(amplifyOutputs).length > 0) {
    return amplifyOutputs;
  }
  if (import.meta.env.VITE_AMPLIFY_OUTPUTS_URL) {
    try {
      const response = await fetch(import.meta.env.VITE_AMPLIFY_OUTPUTS_URL);
      const object = await response.json();
      if (Object.keys(object).length > 0) {
        return object;
      }
    } catch {
      //TODO: once backend is properly setup this should be removed as we want to be notified of any errors fetching the config
      // but for now, we can just return undefined
      return undefined;
    }
  }
  return undefined;
}

export const amplifyBackendClient = generateClient<Schema>();
