import { FormEventHandler, memo, useCallback, useState, JSX } from "react";

import { t, Trans } from "@lingui/macro";
import { DarkButton } from "@mobsuccess-devops/react-ui/Button";
import { Container } from "@mobsuccess-devops/react-ui/Container";
import { AdvancedInput } from "@mobsuccess-devops/react-ui/Form";
import { Separator } from "@mobsuccess-devops/react-ui/Separator";
import { Typo } from "@mobsuccess-devops/react-ui/Typo";
import { Typography } from "@mobsuccess-devops/react-ui/Typography";
import { HStack } from "@mobsuccess-devops/styled-system/jsx";

import styled, { css, useTheme } from "styled-components";

import { useSessionStorage } from "../../../features/storage";
import { AuthActionEnum, useAuth } from "../../../public/auth/auth";

import GoogleSignIn from "./GoogleSignIn";
import { PatSignIn } from "./PatSignIn/PatSignIn";

const FormUI = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

const SeparatorUI = styled(Separator)`
  flex: unset;
`;

const paswordLostCss = css`
  text-decoration: underline;
  cursor: pointer;
`;

enum LoginError {
  None = "none",
  Empty = "empty",
}

export type LoginProps = {
  isLoading?: boolean;
  googleClientId?: string;
  onChangeAuthAction: (action: AuthActionEnum, state?: object) => void;
};

function Login({
  googleClientId,
  onChangeAuthAction,
}: LoginProps): JSX.Element {
  const [form, setForm] = useState<HTMLFormElement | null>(null);

  const [autoLoginGoogle] = useSessionStorage("autoLoginGoogle", {
    defaultValue: true,
  });
  const { signIn } = useAuth();

  const [errors, setErrors] = useState({
    email: LoginError.None,
    password: LoginError.None,
  });
  const { palette } = useTheme();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      const email = e.currentTarget.email.value;
      const password = e.currentTarget.password.value;

      if (!email || !password) {
        setErrors({
          email: email ? LoginError.None : LoginError.Empty,
          password: password ? LoginError.None : LoginError.Empty,
        });
        return;
      }

      await signIn(email, password);
    },
    [signIn],
  );

  const handleClickForgotPassword = useCallback(() => {
    onChangeAuthAction(AuthActionEnum.ForgotPassword, {
      email: form?.email.value,
    });
  }, [form, onChangeAuthAction]);

  const renderError = useCallback((error: LoginError) => {
    switch (error) {
      case LoginError.Empty:
        return {
          state: "error" as const,
          caption: t`#Auth.Login.error.empty`,
        };
      default:
        return {
          state: undefined,
          caption: undefined,
        };
    }
  }, []);

  const orUI = (
    <HStack width="100%" alignItems="center" justifyContent="center" gap="sm">
      <SeparatorUI color={palette.interface.darker[300]} />
      <Typo.Body color="interface.600" transform="uppercase">
        <Trans>#Auth.Login.or</Trans>
      </Typo.Body>
      <SeparatorUI color={palette.interface.darker[300]} />
    </HStack>
  );

  return (
    <FormUI id="auth-form" ref={setForm} onSubmit={handleSubmit}>
      <AdvancedInput
        name="email"
        autoComplete="email"
        containerWidth="100%"
        data-cy="email-input"
        {...renderError(errors.email)}
        label={t`#Auth.Login.emailLabel`}
        placeholder={t`#Auth.Login.emailPlaceholder`}
      />
      <AdvancedInput
        name="password"
        type="password"
        containerWidth="100%"
        data-cy="password-input"
        autoComplete="current-password"
        {...renderError(errors.password)}
        label={t`#Auth.Login.passwordLabel`}
        placeholder={t`#Auth.Login.passwordPlaceholder`}
      />
      <Container
        flexDirection="column"
        gap="24px"
        width="100%"
        alignItems="center"
      >
        <DarkButton
          fill
          size="lg"
          formNoValidate
          form="auth-form"
          type="submit"
          data-cy="login-button"
        >
          {t`#Auth.Login.login`}
        </DarkButton>
        <Typography
          as="span"
          styled={paswordLostCss}
          color={palette.primary.base}
          onClick={handleClickForgotPassword}
        >
          <Trans>#Auth.Login.forgottonPassword</Trans>
        </Typography>
      </Container>
      {import.meta.env.VITE_IS_DEV === "true" && (
        <>
          {orUI}
          <PatSignIn />
        </>
      )}
      {googleClientId && (
        <>
          {orUI}
          <GoogleSignIn
            autoSelect={autoLoginGoogle}
            googleClientId={googleClientId}
          />
        </>
      )}
    </FormUI>
  );
}

export default memo(Login);
