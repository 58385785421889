import { AgnosticRouteMatch, matchPath, resolveTo } from "@remix-run/router";

import { NavigateOptions, Path } from "react-router-dom";

import { KeepParam } from "../../types/KeepParam";
import { OverloadTo } from "../../types/OverloadTo";

import { SearchParams } from "./search-params";

function getPathContributingMatches<
  T extends AgnosticRouteMatch = AgnosticRouteMatch,
>(matches: T[]): T[] {
  return matches.filter(
    (match, index) =>
      index === 0 || (match.route.path && match.route.path.length > 0),
  );
}

type RelativeUrlOptions = {
  from: Path;
  to: OverloadTo;
  keep: Array<KeepParam>;
  options?: NavigateOptions;
  matches: AgnosticRouteMatch[];
  explicitSearchParamsRemoval?: string[];
};

export class RelativeUrl implements Path {
  public pathname: Path["pathname"];
  public search: string;
  public hash: Path["hash"];
  public keep: Array<KeepParam>;
  public explicitSearchParamsRemoval: string[];

  private fromSearch: SearchParams;

  constructor({
    to,
    from,
    options,
    keep,
    matches,
    explicitSearchParamsRemoval,
  }: RelativeUrlOptions) {
    this.keep = keep;
    const target =
      typeof to === "string"
        ? to
        : {
            ...to,
            search: to.search
              ? new SearchParams(to.search).toString()
              : undefined,
          };
    const resolvedTo = resolveTo(
      target,
      getPathContributingMatches(matches).map((match) => match.pathnameBase),
      from.pathname,
      options?.relative === "path",
    );
    this.pathname = resolvedTo.pathname;
    this.search = resolvedTo.search;
    this.hash = resolvedTo.hash;
    this.fromSearch = new SearchParams(from.search);
    this.explicitSearchParamsRemoval = explicitSearchParamsRemoval ?? [];
    this.render();
  }

  private normalizePrefix(str?: string, prefix?: string): string {
    return !str || str === prefix
      ? ""
      : prefix + str.replace(new RegExp(`^\\${prefix}`), "");
  }

  private render(): this {
    const search = new SearchParams(this.search);
    const setValue = (key: string, value: string) => {
      if (this.explicitSearchParamsRemoval.includes(key)) {
        return;
      }
      search.set(key, value);
    };

    for (const keepParam of this.keep) {
      if (
        typeof keepParam === "string" &&
        !search.has(keepParam) &&
        this.fromSearch.has(keepParam)
      ) {
        const valueToSet = this.fromSearch.get(keepParam);
        if (valueToSet) {
          setValue(keepParam, valueToSet);
        }
      } else if (keepParam instanceof RegExp) {
        for (const [key, value] of this.fromSearch) {
          if (keepParam.test(key) && !search.has(key)) {
            setValue(key, value);
          }
        }
      } else if (
        typeof keepParam === "object" &&
        !search.has(keepParam.param) &&
        this.fromSearch.has(keepParam.param) &&
        keepParam.routes.some((route) => !!matchPath(route, this.pathname))
      ) {
        const valueToSet = this.fromSearch.get(keepParam.param);
        if (valueToSet) {
          setValue(keepParam.param, valueToSet);
        }
      }
    }
    this.search = search.toString();
    return this;
  }

  public toString(): string {
    const search = this.normalizePrefix(this.search.toString(), "?");
    const hash = this.normalizePrefix(this.hash, "#");
    return `${this.pathname}${search}${hash}`;
  }
}
