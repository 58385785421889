import { JSX } from "react";

import { useToasters } from "@mobsuccess-devops/react-ui/_PandaArk";
import { useQuery } from "@tanstack/react-query";

type FlashMessagesProps = {
  children: JSX.Element;
};

function FlashMessages({ children }: FlashMessagesProps): JSX.Element {
  const { toast } = useToasters();

  useQuery({
    queryKey: ["flash-message"],
    queryFn() {
      const { success = [], error = [] } = window._msFlashMessages ?? {};

      for (const message of success) {
        toast.success({
          title: message,
        });
      }
      for (const message of error) {
        toast.error({
          title: message,
        });
      }
      return null;
    },
  });
  return children;
}

export default FlashMessages;
